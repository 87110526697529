import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const ConditionsGeneralesVente = ({ data }) => (
  <Layout>
    <SEO title="Conditions Générales de Vente" />

<div style={{
        alignItems: 'stretch',
        WebkitBoxAlign: 'stretch',
        margin: 'auto',
        padding: '30px',
     }}>
    
<h1>Conditions Générales de Vente</h1>

<p>En vigueur au 11/04/2021</p>

<h2>ARTICLE 1 - Champ d'application</h2>

<p>Les présentes Conditions Générales de Vente (dites « CGV ») s'appliquent, sans restriction ni réserve à tout achat des services de suivants :<br/>
Création de sites internet<br/>
Hébergement et maintenance de sites internet<br/>
Modification de sites internet existant<br/>
Accompagnement et formations au numérique<br/>
Gestion de la présence en ligne<br/>
</p>

<p>Tels que proposés par le Prestataire aux clients (« Les Clients ou le Client ») sur le site <Link to="/">https://www.saadify.fr</Link>.<br/>
Les caractéristiques principales des Services sont présentées sur le site internet <Link to="/">https://www.saadify.fr</Link>.<br/>
Le Client est tenu d'en prendre connaissance avant toute passation de commande. Le choix et l'achat d'un Service est de la seule responsabilité du Client.<br/>
</p>

<p>Ces CGV sont accessibles à tout moment sur le site <Link to="/">https://www.saadify.fr</Link> et prévaudront sur toute autre document.<br/>
Le Client non professionnel déclare avoir pris connaissance des présentes CGV et les avoir acceptées au moment de la réalisation du devis par le Prestataire.<br/>
Le Client professionnel peut demander à accéder aux dites CGV à tout moment sans obligation d’être fournies par le Prestataire.<br/>
Sauf preuve contraire, les données enregistrées dans le système informatique du Prestataire constituent la preuve de l'ensemble des transactions conclues avec le Client.<br/>
</p>

<p>
Les coordonnées du Prestataire sont les suivantes :<br/>
DIF Saâd<br/>
2 BIS PLACE DU CH TEAU D'EAU<br/>
Numéro d'immatriculation : RCS Bobigny 789 445 608<br/>
Mail : contact@saadify.fr<br/>
Téléphone : 06 65 36 13 07<br/>
</p>

<p>
Des droits de douane ou autres taxes locales ou droits d'importation ou taxes d'état sont susceptibles d'être exigibles. Ils seront à la charge et relèvent de la seule responsabilité du Client.<br/>
</p>

<h2>ARTICLE 2 - Prix</h2>

<p>
Les Services sont fournis aux tarifs en vigueur figurant sur le site <Link to="/">https://www.saadify.fr</Link>, lors de l'enregistrement de la commande par le Prestataire.<br/>
Les prix sont exprimés en Euros, HT : TVA non applicable - article 293 B du CGI.<br/>
Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Prestataire sur le site <Link to="/">https://www.saadify.fr</Link>.<br/>
Ces tarifs sont fermes et non révisables pendant leur période de validité mais le Prestataire se réserve le droit, hors période de validité, d’en modifier les prix à tout moment.<br/>
Le paiement demandé au Client correspond au montant total de l'achat.<br/>
Une facture est établie par le Prestataire et remise au Client lors de la fourniture des Services commandés.<br/>
</p>

<p>
Certaines commandes peuvent faire l'objet d'un devis préalablement accepté. Les devis établis par le Prestataire sont valables pour une durée de 14 jours après leur établissement.<br/>
</p>

<h2>ARTICLE 3 – Commandes</h2>

<p>
Il appartient au Client de sélectionner sur le site <Link to="/">https://www.saadify.fr</Link> les Services qu'il désire commander, selon les modalités suivantes :<br/>
Le client choisi un service et contacte le Prestataire pour la réalisation d'un devis<br/>
Une fois la validation du devis faite par Le Client, celui-ci recevra un mail l'invitant à régler la facture de la prestation de services<br/>
Une fois la facture réglée, la prestation de service débutera.<br/>
La vente ne sera considérée comme valide qu’après paiement intégral du prix. Il appartient au Client de vérifier l'exactitude de la commande et de signaler immédiatement toute erreur.<br/>
Toute commande passée sur le site <Link to="/">https://www.saadify.fr</Link> constitue la formation d'un contrat conclu à distance entre le Client et le Prestataire.<br/>
Le Prestataire se réserve le droit d'annuler ou de refuser toute commande d'un Client avec lequel il existerait un litige relatif au paiement d'une commande antérieure.<br/>
Le Client pourra suivre l'évolution de sa commande par simple demande.<br/>
</p>

<p>
La passation d'une commande sur le site <Link to="/">https://www.saadify.fr</Link> implique la conclusion d'un contrat d'une durée minimum de 1 an renouvelable pour une même durée par tacite reconduction.<br/>
Aux termes de l'article L 215 -1 du Code de la consommation, reproduit ci-dessous :<br/>
"Pour les contrats de prestations de services conclus pour une durée déterminée avec une clause de reconduction tacite, le professionnel prestataire de services informe le consommateur par écrit, par lettre nominative ou courrier électronique dédiés, au plus tôt trois mois et au plus tard un mois avant le terme de la période autorisant le rejet de la reconduction, de la possibilité de ne pas reconduire le contrat qu'il a conclu avec une clause de reconduction tacite. Cette information, délivrée dans des termes clairs et compréhensibles, mentionne, dans un encadré apparent, la date limite de non-reconduction.<br/>
Lorsque cette information ne lui a pas été adressée conformément aux dispositions du premier alinéa, le consommateur peut mettre gratuitement un terme au contrat, à tout moment à compter de la date de reconduction.<br/>
Les avances effectuées après la dernière date de reconduction ou, s'agissant des contrats à durée indéterminée, après la date de transformation du contrat initial à durée déterminée, sont dans ce cas remboursées dans un délai de trente jours à compter de la date de résiliation, déduction faite des sommes correspondant, jusqu'à celle-ci, à l'exécution du contrat. Les dispositions du présent article s'appliquent sans préjudice de celles qui soumettent légalement certains contrats à des règles particulières en ce qui concerne l'information du consommateur. »<br/>
L'article L215-2 du Code de la consommation exclut l'application de l'art L215-1 aux exploitants des services d'eau potable et d'assainissement, à l’inverse l'article L215-3 du Code de la consommation, dispose que ces règles sont applicables aux contrats conclus entre des professionnels et des non-professionnels.<br/>
L'article L241-3 du sanctionne le professionnel qui n'aurait pas procédé aux remboursements dans les conditions prévues à l'article L 215-1 du Code de la consommation"<br/>
</p>

<h2>ARTICLE 4 - Conditions de paiement</h2>

<p>
Le prix est payé par voie de paiement sécurisé, selon les modalités suivantes :<br/>
<ul>
  <li>paiement par carte bancaire</li>
  <li>ou paiement par virement bancaire sur le compte bancaire du Vendeur (dont les coordonnées sont communiquées au Client lors de la passation de la commande)</li>
</ul>
Le prix est payable comptant par le Client, en totalité au jour de la passation de la commande.<br/>
</p>

<p>
Les données de paiement sont échangées en mode crypté grâce au protocole défini par le prestataire de paiement agréé intervenant pour les transactions bancaires réalisées sur le site <Link to="/">https://www.saadify.fr</Link>.<br/>
</p>

<p>
Les paiements effectués par le Client ne seront considérés comme définitifs qu'après encaissement effectif des sommes dues, par le Prestataire.<br/>
Le Prestataire ne sera pas tenu de procéder à la fourniture des Services commandés par le Client si celui-ci ne lui en paye pas le prix en totalité dans les conditions ci-dessus indiquées.<br/>
</p>

<h2>ARTICLE 5 - Fourniture des Prestations</h2>

<p>
Les Services commandés par le Client seront fournis selon les modalités suivantes : <br/>
Une fois le paiement effectué, dans le cas de la création d'un site web le Prestataire s’engage à une livraison sous 7 jours.<br/>
Concernant tout autre type de prestations, le délai de réalisation sera convenu avec Le Client.<br/>
Lesdits Services seront fournis dans un délai maximum de 14 jours à compter de la validation définitive de la commande du Client, dans les conditions prévues aux présentes CGV à l'adresse indiquée par le Client lors de sa commande sur le site <Link to="/">https://www.saadify.fr</Link> .<br/>
Le Prestataire s'engage à faire ses meilleurs efforts pour fournir les Services commandés par le Client, dans le cadre d'une obligation de moyen et dans les délais ci-dessus précisés.<br/>
Si les Services commandés n'ont pas été fournis dans un délai de 14 jours après la date indicative de fourniture, pour toute autre cause que la force majeure ou le fait du Client, la vente des Services pourra être résolue à la demande écrite du Client dans les conditions prévues aux articles L 216-2, L 216-3 et L241-4 du Code de la consommation. Les sommes versées par le Client lui seront alors restituées au plus tard dans les quatorze jours qui suivent la date de dénonciation du contrat, à l'exclusion de toute indemnisation ou retenue.<br/>
En cas de demande particulière du Client concernant les conditions de fourniture des Services, dûment acceptées par écrit par le Prestataire, les coûts y étant liés feront l'objet d'une facturation spécifique complémentaire ultérieure.<br/>
</p>

<p>
A défaut de réserves ou réclamations expressément émises par le Client lors de la réception des Services, ceux-ci seront réputés conformes à la commande, en quantité et qualité.<br/>
Le Client disposera d'un délai de 14 jours à compter de la fourniture des Services pour émettre des réclamations par e-mail, avec tous les justificatifs y afférents, auprès du Prestataire.<br/>
Aucune réclamation ne pourra être valablement acceptée en cas de non respect de ces formalités et délais par le Client.<br/>
Le Prestataire remboursera ou rectifiera dans les plus brefs délais et à ses frais les Services dont le défaut de conformité aura été dûment prouvé par le Client.<br/>
</p>

<h2>ARTICLE 6 - Droit de rétractation</h2>

<p>
Compte tenu de la nature des Services fournis, les commandes passées par le Client ne bénéficient pas du droit de rétractation.<br/>
Le contrat est donc conclu de façon définitive dès la passation de la commande par le Client selon les modalités précisées aux présentes CGV.<br/>
</p>

<h2>ARTICLE 7 - Responsabilité du Prestataire - Garanties</h2>

<p>
Le Prestataire garantit, conformément aux dispositions légales et sans paiement complémentaire, le Client, contre tout défaut de conformité ou vice caché, provenant d'un défaut de conception ou de réalisation des Services commandés dans les conditions et selon les modalités suivantes :<br/>
Dispositions relatives aux garanties légales<br/>
Article L217-4 du Code de la consommation<br/>
« Le vendeur est tenu de livrer un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance. Il répond également des défauts de conformité résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité. »<br/>
Article L217-5 du Code de la consommation<br/>
« Le bien est conforme au contrat :<br/>
1° S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant :<br/>
- s'il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon ou de modèle ;<br/>
- s'il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans la publicité ou l'étiquetage ;<br/>
2° Ou s'il présente les caractéristiques définies d'un commun accord par les parties ou est propre à tout usage spécial recherché par l'acheteur, porté à la connaissance du vendeur et que ce dernier a accepté. »<br/>
Article L217-12 du Code de la consommation<br/>
« L'action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du bien. »<br/>
Article L217-16 du Code de la consommation.<br/>
« Lorsque l'acheteur demande au vendeur, pendant le cours de la garantie commerciale qui lui a été consentie lors de l'acquisition ou de la réparation d'un bien meuble, une remise en état couverte par la garantie, toute période d'immobilisation d'au moins sept jours vient s'ajouter à la durée de la garantie qui restait à courir. Cette période court à compter de la demande d'intervention de l'acheteur ou de la mise à disposition pour réparation du bien en cause, si cette mise à disposition est postérieure à la demande d'intervention. »<br/>
Afin de faire valoir ses droits, le Client devra informer le Prestataire, par écrit (mail ou courrier), de l'existence des vices ou défauts de conformité.<br/>
Le Prestataire remboursera ou rectifiera ou fera rectifier (dans la mesure du possible) les services jugés défectueux dans les meilleurs délais et au plus tard dans les 30 jours suivant la constatation par le Prestataire du défaut ou du vice. Ce remboursement pourra être fait par virement ou chèque bancaire.<br/>
La garantie du Prestataire est limitée au remboursement des Services effectivement payés par le Client.<br/>
Le Prestataire ne pourra être considéré comme responsable ni défaillant pour tout retard ou inexécution consécutif à la survenance d'un cas de force majeure habituellement reconnu par la jurisprudence française.<br/>
Les Services fournis par l'intermédiaire du site <Link to="/">https://www.saadify.fr</Link> du Prestataire sont conformes à la réglementation en vigueur en France. La responsabilité du Prestataire ne saurait être engagée en cas de non respect de la législation du pays dans lequel les Services sont fournis, qu'il appartient au Client, qui est seul responsable du choix des Services demandés, de vérifier.<br/>
</p>

<h2>ARTICLE 8 - Données personnelles</h2>

<p>
Le Client est informé que la collecte de ses données à caractère personnel est nécessaire à la vente des Services et leur réalisation et délivrance, confiées au Prestataire. Ces données à caractère personnel sont récoltées uniquement pour l’exécution du contrat de prestations de services.<br/>
</p>

<h3>8.1 Collecte des données à caractère personnel</h3>

<p>
Les données à caractère personnel qui sont collectées sur le site <Link to="/">https://www.saadify.fr</Link> sont les suivantes :<br/>
</p>

<p>
<b>Commande de Prestations :</b><br/>
Lors de la commande de Prestations par le Client :<br/>
Noms, prénoms, adresse postale, numéro de téléphone et adresse e-mail.<br/>
</p>

<h3>8.2 Destinataires des données à caractère personnel</h3>

<p>
Les données à caractère personnel sont réservées à l’usage unique du Prestataire et de ses salariés.<br/>
Le responsable de traitement des données est le Prestataire, au sens de la loi Informatique et libertés et à compter du 25 mai 2018 du Règlement 2016/679 sur la protection des données à caractère personnel.<br/>
</p>

<h3>8.3 Limitation du traitement</h3>

<p>
Sauf si le Client exprime son accord exprès, ses données à caractère personnelles ne sont pas utilisées à des fins publicitaires ou marketing.<br/>
</p>

<h3>8.4 Durée de conservation des données</h3>

<p>
Le Prestataire conservera les données ainsi recueillies pendant un délai de 5 ans, couvrant le temps de la prescription de la responsabilité civile contractuelle applicable.<br/>
</p>

<h3>8.5 Sécurité et confidentialité</h3>

<p>
Le Prestataire met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toutefois il est à signaler qu’Internet n’est pas un environnement complètement sécurisé et le Prestataire ne peut garantir la sécurité de la transmission ou du stockage des informations sur Internet.<br/>
</p>

<h3>8.6 Mise en œuvre des droits des Clients et utilisateurs</h3>

<p>
En application de la réglementation applicable aux données à caractère personnel, les Clients et utilisateurs du site <Link to="/">https://www.saadify.fr</Link> disposent des droits suivants :<br/>
<ul>
  <li>Ils peuvent mettre à jour ou supprimer les données qui les concernent de la manière suivante : En demandant par email.</li>
  <li>Ils peuvent supprimer leur compte en écrivant à l’adresse électronique indiqué à l’article 9.3 « Responsable de traitement »</li>
  <li>Ils peuvent exercer leur droit d’accès pour connaître les données personnelles les concernant en écrivant à l’adresse indiqué à l’article 9.3 « Responsable de traitement »</li>
  <li>Si les données à caractère personnel détenues par le Prestataire sont inexactes, ils peuvent demander la mise à jour des informations des informations en écrivant à l’adresse indiqué à l’article 9.3 « Responsable de traitement »</li>
  <li>Ils peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données en écrivant à l’adresse indiqué à l’article 9.3 « Responsable de traitement »</li>
  <li>Ils peuvent également solliciter la portabilité des données détenues par le Prestataire vers un autre prestataire</li>
  <li>Enfin, ils peuvent s’opposer au traitement de leurs données par le Prestataire</li>
</ul>
Ces droits, dès lors qu’ils ne s’opposent pas à la finalité du traitement, peuvent être exercé en adressant une demande par courrier ou par E-mail au Responsable de traitement dont les coordonnées sont indiquées ci-dessus.<br/>
Le responsable de traitement doit apporter une réponse dans un délai maximum d’un mois.<br/>
En cas de refus de faire droit à la demande du Client, celui-ci doit être motivé.<br/>
Le Client est informé qu’en cas de refus, il peut introduire une réclamation auprès de la CNIL (3 place de Fontenoy, 75007 PARIS) ou saisir une autorité judiciaire.<br/>
Le Client peut être invité à cocher une case au titre de laquelle il accepte de recevoir des mails à caractère informatifs et publicitaires de la part du Prestataire. Il aura toujours la possibilité de retirer son accord à tout moment en contactant le Prestataire (coordonnées ci-dessus) ou en suivant le lien de désabonnement.<br/>
</p>

<h2>ARTICLE 9 - Propriété intellectuelle</h2>

<p>
Le contenu du site <Link to="/">https://www.saadify.fr</Link> est la propriété du Vendeur et de ses partenaires et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.<br/>
Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.<br/>
</p>

<h2>ARTICLE 10 - Droit applicable - Langue</h2>

<p>
Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit français.<br/>
Les présentes CGV sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.<br/>
</p>

<h2>ARTICLE 11 - Litiges</h2>

<p>
Pour toute réclamation merci de contacter le service clientèle à l’adresse postale ou mail du Prestataire indiquée à l’ARTICLE 1 des présentes CGV.<br/>
Le Client est informé qu'il peut en tout état de cause recourir à une médiation conventionnelle, auprès des instances de médiation sectorielles existantes ou à tout mode alternatif de règlement des différends (conciliation, par exemple) en cas de contestation. <br/>
En l’espèce, le médiateur désigné est<br/>
AMIDIF - Association des médiateurs indépendants d'Ile de France<br/>
1 place de Fleurus - 77100 Meaux<br/>
<a href="http://www.amidif.com/" target="_blank" rel="noreferrer">http://www.amidif.com/</a><br/>
E-mail : contact@amidif.com <br/>
</p>

<p>
Le Client est également informé qu’il peut, également recourir à la plateforme de Règlement en Ligne des Litige (RLL) : <a href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show" target="_blank" rel="noreferrer">https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show</a><br/>
Tous les litiges auxquels les opérations d'achat et de vente conclues en application des présentes CGV et qui n’auraient pas fait l’objet d’un règlement amiable entre le vendeur ou par médiation, seront soumis aux tribunaux compétents dans les conditions de droit commun.<br/>
</p>

<p>
Réalisé sur <a href="https://www.legalplace.fr" target="_blank" rel="noreferrer">https://www.legalplace.fr</a>
</p>

</div>

  </Layout>
)

export default ConditionsGeneralesVente
